import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import map from 'lodash/map';
import { graphql } from 'gatsby';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactGA from 'react-ga';

import * as API from '../apis';
import breakpoints from '../styles/breakpoints';
import { ACTION, CATEGORY } from '../constants/ga';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Input from '../components/Input';
import Button from '../components/Button';
import Message from '../components/Message';
import MutedText from '../components/MutedText';

const Card = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 5rem 1rem;

  @media (max-width: ${breakpoints.md}) {
    padding: 1.5rem 1rem;
  }
`;

const MainContent = styled.div`
  min-height: 100vh;
  background: rgb(245, 247, 250);
`;

const Header = styled.header`
  padding: 2.5rem;
  font-size: 1.375rem;
  font-weight: 500;
  text-align: center;
  color: #343434;
  background-color: #ffffff;
  border-bottom: solid 1px #eceff0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }
`;

const Body = styled.section`
  padding: 1.5rem 3rem;
  text-align: left;
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: auto;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem 2rem 2rem;
  }
`;

const Label = styled.label.attrs({ className: 'label' })`
  display: block;
  line-height: 21px;
  font: inherit;
  font-size: 1rem;
  font-weight: 500;
  color: #2e2e2e;
  padding: 6px 0;
  text-align: left;
`;

const Field = styled.div`
  margin-bottom: 1.5rem;
`;

const TypeSelect = styled(Select)`
  > div[class*='-control'] {
    height: 3rem;
  }

  > div[class*='-menu'] {
    margin: 0;
  }
`;

const SubmitButton = styled(Button)`
  padding-left: 4.25rem;
  padding-right: 4.25rem;
  margin-right: 1.5rem;

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
    margin: 0 0 1rem 0;
    justify-content: center;
  }
`;

const ResendButton = styled(MutedText)`
  margin-top: 1rem;
  cursor: pointer;
`;

const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  overflow: hidden;
`;

function ContactPage({ data }) {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [content, setContent] = useState('');
  const [type, setType] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [captcha, setCaptcha] = useState();
  const { contactTypes } = data.trangCommon;
  const isValid = name && email && phoneNumber && content && type && captcha;

  const resetForm = () => {
    setName('');
    setEmail('');
    setPhoneNumber('');
    setContent('');
    setType(null);
  };

  const handleSubmit = async () => {
    const handleError = error => {
      setErrorMessage('Có lỗi xảy ra. Vui lòng thử lại sau.');
      console.error(error);
    };

    try {
      setLoading(true);
      setErrorMessage('');
      const response = await API.submitContact({
        name,
        email,
        phoneNumber,
        content,
        type: type.value,
        captcha,
      });
      setLoading(false);

      if (response && response.id) {
        window.scrollTo(0, 0);
        setSubmitted(true);
        resetForm();
      } else {
        handleError(response);
      }
    } catch (error) {
      setLoading(false);
      handleError(error);
    }

    ReactGA.event({
      category: CATEGORY.USER_MANAGEMENT,
      action: ACTION.SUBMIT_CONTACT_FORM,
      value: type.value,
    });
  };

  return (
    <Layout mainContent={MainContent}>
      <SEO title="Liên hệ" />
      {submitted && (
        <Card>
          <Body>
            <center>
              Thông tin của bạn đã được gửi. Trạng sẽ liên hệ lại với bạn trong thời gian sớm nhất!
              <ResendButton onClick={() => setSubmitted(false)}>Bạn muốn gửi lại?</ResendButton>
            </center>
          </Body>
        </Card>
      )}
      {!submitted && (
        <Card>
          <Header>Liên hệ</Header>
          <Body>
            <Field>
              <Label>Bạn cần liên hệ Trạng về vấn đề gì?</Label>
              <TypeSelect
                isSearchable
                isClearable
                isDisabled={loading}
                placeholder="Vui lòng chọn"
                value={type}
                options={map(contactTypes, t => ({ value: t, label: t }))}
                onChange={value => setType(value)}
              />
            </Field>
            <Field>
              <Input
                label="Họ và tên"
                placeholder="Họ và tên"
                value={name}
                onChange={event => setName(event.target.value)}
                disabled={loading}
              />
            </Field>
            <Field>
              <Input
                label="Email"
                placeholder="Địa chỉ email của bạn"
                value={email}
                onChange={event => setEmail(event.target.value)}
                disabled={loading}
              />
            </Field>
            <Field>
              <Input
                label="Số điện thoại"
                placeholder="Số điện thoại"
                value={phoneNumber}
                onChange={event => setPhoneNumber(event.target.value)}
                disabled={loading}
              />
            </Field>
            <Field>
              <Input
                label="Nội dung"
                placeholder="Nội dung trao đổi"
                value={content}
                onChange={event => setContent(event.target.value)}
                disabled={loading}
                type="textarea"
              />
            </Field>
            <center>
              <SubmitButton loading={loading} disabled={!isValid || loading} onClick={handleSubmit}>
                Gửi
              </SubmitButton>
            </center>
            {errorMessage && <Message error>{errorMessage}</Message>}
            <CaptchaWrapper>
              <ReCAPTCHA sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY} onChange={setCaptcha} />
            </CaptchaWrapper>
          </Body>
        </Card>
      )}
    </Layout>
  );
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    trangCommon: PropTypes.shape({
      contactTypes: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    trangCommon {
      contactTypes
    }
  }
`;
